import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/chatIndex',
  //   name: 'AI萧助手',
  //   component: () => import('../views/chatPage/chatIndex.vue')
  // },
  // {
  //   path: '/chatChild',
  //   name: 'AI萧助手',
  //   component: () => import('../views/chatPage/chatChild.vue')
  // },
  // {
  //   path: '/chatParent',
  //   name: 'AI萧助手',
  //   component: () => import('../views/chatPage/chatParent.vue')
  // },
  // {
  //   path: '/index',
  //   name: 'index',
  //   component: () => import('../views/indexs/index')
  // },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index')
  },
  {
    path: '/chatPage',
    name: 'chatPage',
    component: () => import('../views/chatPage/newChatAdd.vue')
  },
  {
    path: '/auditPage',
    name: 'auditPage',
    component: () => import('../views/auditPage/index.vue')
  },
  //  {
  //   path: '/viewPdf',
  //   name: 'AI萧助手',
  //   component: () => import('../views/viewPdf/index.vue')
  // },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from) => {
  document.title = '办案系统';
})
export default router
