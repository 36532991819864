const DEFAULT_CONFIG = {
	//版本号
	APP_VER: "2.0.0",
}

//合并业务配置
import MY_CONFIG from "./config"
Object.assign(DEFAULT_CONFIG, MY_CONFIG)

// 如果生产模式，就合并动态的APP_CONFIG
// public/config.js
if(process.env.NODE_ENV === 'production'){
	Object.assign(DEFAULT_CONFIG)
}

export default DEFAULT_CONFIG